<template>
	<div class="base-container">
		<div class="base-container__header">
			<div v-if="title" class="base-container__title">{{ title }}</div>
			<div class="base-container__options">
				<slot name="right" />
			</div>
		</div>
		<div class="base-container__content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseContainer',
	props: {
		title: {
			type: String,
			default: null
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
.base-container {
	background-color: $container-background;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	box-sizing: border-box;
	transition: 0.5s;
	margin-bottom: 20px;
	padding: 16px;

	&:hover {
		-webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
		box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
	}

	.base-container__header {
		display: flex;
		justify-content: space-between;
		margin: 0 -16px;
		padding: 0 16px 16px 16px;
		border-bottom: 1px solid $main-border;
		border-bottom-style: dashed;

		.base-container__title {
			font-size: 20px;
		}
	}

	.base-container__content {
		padding: 10px 0;
	}
}
</style>
