export default {
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávky"])},
  "created-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorené objednávky"])},
  "create-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorte objednávku"])},
  "created-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka vytvorená"])},
  "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily objednávky"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
  "start-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite písať.."])},
  "start-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto štartu"])},
  "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
  "phone-sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón odosielateľa"])},
  "phone-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón príjemcu"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
  "note-sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka odosielateľa"])},
  "note-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka príjemcu"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "express-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expresná doprava"])},
  "destination-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto určenia"])},
  "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
  "customer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno zákazníka"])},
  "customer-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón zákazníka"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štart"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cieľ"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcie"])},
  "driver-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno vodiča"])},
  "driver-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón vodiča"])},
  "driver-car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto vodiča"])},
  "rows-per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet riadkov na stránku:"])},
  "no-avaliable-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne dostupné údaje"])},
  "transporting-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručované objednávky"])},
  "history-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História objednávok"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "chat-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlite správu kuriérovi"])},
  "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vodič"])},
  "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas vyzdvihnutia"])},
  "pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vyzdvihnutia"])},
  "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dátum"])},
  "is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je povinné pole"])},
  "upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normálna"])},
  "express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expresná"])},
  "select-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte čas"])},
  "express-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expresné objednávky"])},
  "upcomming-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normálne objednávky"])}
}