import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView'
import DashboardView from "@/views/DashboardView";
import AdminView from "@/views/AdminView";
import CreateOrderView from "@/views/CreateOrderView";
import HistoryOrdersView from "@/views/HistoryOrdersView";
import ChatOrderView from "@/views/ChatOrderView.vue";

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    props: true,
    children: [
      {
        path: '/admin/orders',
        name: 'orders',
        component: DashboardView
      },
      {
        path: '/admin/orders/new',
        name: 'orders-new',
        component: CreateOrderView,
      },
      {
        path: '/admin/orders/chat/:orderId',
        name: 'orders-chat',
        component: ChatOrderView,
      },
      {
        path: '/admin/orders/history',
        name: 'orders-history',
        component: HistoryOrdersView,
      },
    ]
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
