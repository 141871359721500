import { createStore } from 'vuex'
import translations from './modules/translations'
import theme from './modules/theme'
import sidebar from './modules/sidebar'
//import geolocation from './modules/geolocation'
import device from './modules/device'
//import map from './modules/map'
import user from './modules/user'
import rest from './modules/rest'
import tooltip from './modules/tooltip'

export default createStore({
	modules: [
		translations,
		theme,
		sidebar,
		//geolocation,
		device,
		rest,
		//map,
		user,
		tooltip
	]
})
