import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import rest from "./scripts/rest";
import helper from "./scripts/helper";
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/css/normalize.css'
import './assets/scss/global.scss'
import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css';

document.documentElement.setAttribute('theme', 'light')

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(VueAxios, axios)
    .use(rest)
    .use(BalmUI)
    .use(BalmUIPlus)
    .use(helper)
    .mount('#app')


