<template>
	<div class="dashboard">
		<h2 class="page-title">{{ $t('history-orders') }}</h2>
    <div>
      <div class="dashboard__container">
        <div class="dashboard__content">

          <base-container :title="$t('express-orders')">

            <div>
              <div class="datatable-search">
                <ui-form-field class="tw-mb-4">
                  <label class="required">{{ $t('search') }}:</label>
                  <ui-textfield
                      :placeholder="$t('start-typing')"
                      input-type="search"
                      v-model="searchValueExpress"
                      outlined
                  >
                  </ui-textfield>
                </ui-form-field>
              </div>

              <Vue3EasyDataTable
                  :headers="headers"
                  :items="historyOrdersExpress"
                  :search-value="searchValueExpress"
                  :rowsPerPageMessage="$t('rows-per-page')"
                  :emptyMessage="$t('no-avaliable-data')"
              />
            </div>

          </base-container>

          <base-container :title="$t('upcomming-orders')">

            <div>
              <div class="datatable-search">
                <ui-form-field class="tw-mb-4">
                  <label class="required">{{ $t('search') }}:</label>
                  <ui-textfield
                      :placeholder="$t('start-typing')"
                      input-type="search"
                      v-model="searchValueUpcomming"
                      outlined
                  >
                  </ui-textfield>
                </ui-form-field>
              </div>

              <Vue3EasyDataTable
                  :headers="headers"
                  :items="historyOrdersUpcomming"
                  :search-value="searchValueUpcomming"
                  :rowsPerPageMessage="$t('rows-per-page')"
                  :emptyMessage="$t('no-avaliable-data')"
              />
            </div>

          </base-container>

        </div>
      </div>
    </div>
	</div>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'
import {restPost} from "@/scripts/rest";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {getOrderType} from "@/scripts/helper";

export default {
	name: 'DashboardView',
	components: {
		BaseContainer,
    Vue3EasyDataTable
	},
  computed: {
    upcomming() {
      return this.$t('upcomming')
    },
    express() {
      return this.$t('express')
    },
    headers() {
      return [
        {text: this.$t('status'), value: "status", sortable: true},
        {text: this.$t('project'), value: "project", sortable: true},
        //{text: this.$t('type'), value: "type", sortable: true},
        {text: this.$t('customer-name'), value: "customer_name", sortable: true},
        {text: this.$t('customer-phone'), value: "customer_phone", sortable: true},
        {text: this.$t('start'), value: "start", sortable: true},
        {text: this.$t('end'), value: "end", sortable: true},
        {text: this.$t('price'), value: "price", sortable: true},
      ];
    }
  },
	data() {
		return {
      historyOrdersExpress: [],
      historyOrdersUpcomming: [],
			tableSorter: null,
      searchValueExpress: '',
      searchValueUpcomming: '',
		}
	},
  created() {
    this.loadHistoryOrders();
  },
  methods: {
    loadHistoryOrders: function()
    {
      let thisElement = this;
      restPost("/customer/history/readList", {listSize: 10, listPage: 1, serviceCode: 'taxi', subserviceCode: 'courier'})
          .then((response) =>
          {
            //console.log(response)
            thisElement.historyOrders = [];
            if(response.data && response.data.historyList.ridesClosed)
            {
              for(let i in response.data.historyList.ridesClosed)
              {
                let order = response.data.historyList.ridesClosed[i];

                let orderRow = {
                  'status' : order.status,
                  'project': order.orderProject,
                  //'type': printOrderType(order, this.express, this.upcomming),
                  'customer_name': order.orderCompanyCustomerName,
                  'customer_phone': order.orderCompanyCustomerPhone,
                  'start': order.places[0].name,
                  'end': order.places[1].name,
                  'price': order.payment ? Math.round(order.payment.value * 100) / 100 + ' ' + order.payment.currency : '',
                  'id': order.orderId,
                };

                if(getOrderType(order) === 'express') {
                  thisElement.historyOrdersExpress.push(orderRow);
                } else {
                  thisElement.historyOrdersUpcomming.push(orderRow);
                }
              }
            }
          });

    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard__map {
	height: 300px;
}

.dashboard__inforgraphics {
	display: flex;

	.dashboard__graph {
		width: 50%;
		margin-right: 10px;
	}

	.dashboard__boxes {
		width: 50%;
		margin-left: 10px;

		.dashboard__box-set {
			display: flex;
			width: 100%;
			height: fit-content;
			margin-bottom: 10px;
			.dashboard__info-box {
				width: 50%;

				&--left {
					margin-right: 5px;
				}

				&--right {
					margin-left: 5px;
				}
			}
		}
	}
}

</style>
