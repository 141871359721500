<template>
	<li
		class="base-list-item"
		:class="{
			'base-list-item--checkbox': checkbox,
			'base-list-item--selected': opened && data.children,
			'base-list-item--selected': data.route === getFullPath
		}"
	>
		<base-checkbox
			v-if="checkbox"
			:custom-id="checkboxId"
			:model-value="data.checkbox"
			@update:model-value="$emit('checkbox', $event)"
		/>
		<div
			class="base-list-item__item"
			tabindex="0"
			@mousedown="handleClick(data)"
		>
			<div
				class="base-list-item__main mdc-deprecated-list-item"
				:class="{ 'base-list-item__main--sidebar': sidebar }"
			>
				<span class="mdc-list-item__ripple" />
				<span
					v-if="data.icon"
					class="base-list-item__icon material-icons mdc-deprecated-list-item__graphic"
				>
					{{ data.icon }}
				</span>
				<span
					class="base-list-item__text mdc-deprecated-list-item__text"
				>
					{{ data.label || data }}
				</span>
			</div>
			<div
				v-if="drag"
				class="base-list-item__drag"
				:class="[
					grabbing
						? 'base-list-item__icon--grabbing'
						: 'base-list-item__icon--grab'
				]"
				@mousedown.exact="grabbing = true"
				@mouseup.exact="grabbing = false"
			>
				<div
					class="base-list-item__icon material-icons"
					@click.stop="handleCollapse"
				>
					<span
						class="base-list-item__icon--drag"
						:class="{ 'base-list-item__icon--rotate': !opened }"
					>
						drag_indicator
					</span>
				</div>
			</div>
			<div v-if="data.children" class="base-list-item__collapse">
				<div
					class="base-list-item__icon base-list-item__icon--hover material-icons"
					:class="{ 'base-list-item__icon': data.children }"
					@click.stop="handleCollapse"
				>
					<span
						class="base-list-item__arrow"
						:class="{ 'base-list-item__icon--rotate': !opened }"
					>
						expand_more
					</span>
				</div>
			</div>
		</div>
		<div v-show="data.children" class="base-list-item__transition">
			<ul
				class="base-list-item__children"
				:style="{ marginTop: getMargin }"
			>
				<base-list-item
					v-for="(child, index) in data.children"
					:key="`${child.label || child}-${index}`"
					:data="child"
					@list-click="handleClick(child)"
				/>
			</ul>
		</div>
	</li>
</template>

<script>
import BaseCheckbox from '@/components/BaseCheckbox'
import { uuid } from '@/modules/uuid'

export default {
	name: 'BaseListItem',
	components: {
		BaseCheckbox
	},
	props: {
		data: {
			type: [String, Number, Object],
			required: true
		},
		isOpened: {
			type: Boolean,
			default: false
		},
		/**
		 * Changes the design to fit the sidebar design
		 */
		sidebar: {
			type: Boolean,
			default: false
		},
		checkbox: {
			type: Boolean,
			default: false
		},
		drag: {
			type: Boolean,
			default: false
		},
		checkboxId: {
			type: String,
			default: null
		},
		checkboxValue: {
			type: Boolean,
			default: false
		},
		sizeSmall: {
			type: Boolean,
			default: false
		}
	},
	emits: ['listClick', 'checkbox'],
	data() {
		return {
			id: null,
			opened: false,
			height: 0,
			grabbing: false
		}
	},
	computed: {
		getMargin() {
			return this.opened ? '0' : `-${this.height}px`
		},
		getFullPath() {
			return this.$route.fullPath
		}
	},
	watch: {
		isOpened(newVal) {
			if (newVal) {
				if (!this.opened) {
					this.opened = true
				}
			} else {
				if (this.opened) {
					this.opened = false
				}
			}
		}
	},
	created() {
    this.id = uuid()

		if (this.isOpened && this.data.children) {
			this.opened = true
		}
	},
	mounted() {
		const list = this.$el.querySelector('ul')
		this.height = list.scrollHeight

		setTimeout(() => {
			list.classList.add('base-list-item__children--transition')
		}, 1)
	},
	methods: {
		handleClick(data) {
			if (data.route) {
				this.$router.push(data.route)
			}

			this.opened = true
			if (
				typeof data === 'object' &&
				!Array.isArray(data) &&
				data !== null
			) {
				this.$emit('listClick', { ...data })
			} else {
				this.$emit('listClick', data)
			}
		},
		handleCollapse() {
			this.opened = !this.opened
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
@import "../assets/scss/mixins";
.base-list-item {
	@include no-select;
	box-sizing: border-box;
	padding-top: 6px;

	.base-list-item__item {
		display: flex;
		flex: 1;

		.base-list-item__main {
			display: flex;
			align-items: center;
			width: 100%;

			&.base-list-item__small {
				height: 28px;
			}

			.base-list-item__text {
				color: $text-color;
			}

			&--sidebar {
				margin: 2px 8px 8px 8px;
			}
		}

		.base-list-item__drag {
			display: flex;
			align-items: center;
		}

		.base-list-item__icon {
			color: $text-color;
			transition: $transition;
		}

		.base-list-item__collapse {
			display: flex;
			align-items: center;
			margin: 2px 8px 8px 8px;

			.base-list-item__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				border-radius: 4px;
				cursor: pointer;

				.base-list-item__arrow {
					transition: $transition;
				}

				&--hover:hover {
					background-color: #dddddf;
				}
			}
		}

		.base-list-item__icon--rotate {
			transform: rotate(-90deg);
		}
		.base-list-item__icon--grab {
			cursor: grab;
		}

		.base-list-item__icon--grabbing {
			cursor: grabbing;
		}
	}

	.base-list-item__transition {
		overflow: hidden;
	}

	.base-list-item__children {
		padding: 0 0 0 0;
		box-sizing: border-box;
		background-color: $sidebar-children-background;
	}

	.base-list-item__children--transition {
		transition: $transition;
	}

	&--selected {
		background-color: $sidebar-selected-background;
	}

	&--checkbox {
		display: flex;
	}
}
</style>
