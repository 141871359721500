<template>
    <ui-alert :state="messageClass" closable>{{ messageValue }}</ui-alert>
</template>

<script>
export default
{
    name: "InfoMessage",
    props: ['messageValue', 'messageClass']
}
</script>
