const user = {
	state: {
		userCreated: 1628069830878,
		userId: 37493,
		userKey: 'vwfRg2kp4k',
		userLogin: 'lacbab8@hopin.sk'
	},

	getters: {
		getUser: (state) => state
	}
}

export default user
