import router from "@/router";

export function isUserLogged()
{
    return (localStorage.getItem('logged') !== null);
}

export function redirectIfLogged(where)
{
    if(localStorage.getItem('logged') !== null)
    {
        router.push(where);
    }
}

export function assertLoggedUser()
{
    if(localStorage.getItem('logged') === null)
    {
        router.push({name: "login"});
    }
}

export function logoutUser()
{
    localStorage.removeItem('logged');
    router.push({name: "login"});
}

export function makeZeroInFront(val)
{
    val = val.toString();
    if(val.length === 1)
    {
        return "0" + val;
    }
    else
    {
        return val;
    }
}

export const btnStatuses = {
    DEFAULT: 1,
    SELECTED: 2,
    DISABLED: 3
}

export const courierCodes = {
    NOW: "deliveryCourierNowPortal",
    LATER: "deliveryCourierLaterPortal"
}

export function printOrderType(order, express = "express", upcomming = "upcomming") {
    if(!order.serviceCourierCode) {
        return '-';
    }
    if(order.serviceCourierCode === 'deliveryCourierNowPortal' || order.serviceCourierCode === 'deliveryCourierNow') {
        return express;
    }
    if(order.serviceCourierCode === 'deliveryCourierLaterPortal' || order.serviceCourierCode === 'deliveryCourierLater') {
        return upcomming;
    }
    return '-';
}

export function getOrderType(order) {
    if(!order.serviceCourierCode) {
        return false;
    }
    if(order.serviceCourierCode === 'deliveryCourierNowPortal' || order.serviceCourierCode === 'deliveryCourierNow') {
        return 'express';
    }
    if(order.serviceCourierCode === 'deliveryCourierLaterPortal' || order.serviceCourierCode === 'deliveryCourierLater') {
        return 'upcomming';
    }
    return false;
}