const tooltip = {
	state: {
		opened: false,
		// id of the shown tooltip text
		show: ''
	},

	getters: {
		isTooltipOpened: (state) => state.opened,
		isTooptipText: (state) => state.show
	},

	mutations: {
		SET_TOOLTIP(state, bool) {
			state.opened = bool
		},
		SET_TOOLTIP_TEXT(state, id) {
			state.show = id
		}
	},

	actions: {
		setTooltip({ commit }, bool) {
			commit('SET_TOOLTIP', bool)
		},
		setTooltipText({ commit }, id) {
			commit('SET_TOOLTIP_TEXT', id)
		}
	}
}

export default tooltip
