<template>
	<div class="layout">
		<the-header />
		<div class="layout__main">
			<the-sidebar />
			<div class="layout__content">
        <slot></slot>
			</div>
		</div>
		<the-footer />
	</div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheSidebar from '@/components/TheSidebar'
import TheFooter from '@/components/TheFooter'

export default {
	name: 'TheLayout',
	components: {
		TheHeader,
		TheSidebar,
		TheFooter
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.layout {
  padding-top: $header-height;

  .layout__main {
    display: flex;
    min-height: calc(100vh - ($header-height) - 60px);

    .layout__content {
      width: 100%;
      padding: 20px;
      overflow: auto;
    }
  }
}
</style>
