<template>
	<the-sidebar-desktop v-if="$_window_isDesktop">
		<base-list
			:list="sidebar"
			:sidebar="true"
			@list-click="handleListClick"
		/>
	</the-sidebar-desktop>
	<the-sidebar-modal v-else>
		<base-list
			:list="sidebar"
			:sidebar="true"
			@list-click="handleListClick"
		/>
	</the-sidebar-modal>
</template>

<script>
import TheSidebarModal from '@/components/TheSidebarModal'
import TheSidebarDesktop from '@/components/TheSidebarDesktop'
import BaseList from '@/components/BaseList'
import { mapActions } from 'vuex'
import windowMixin from '@/mixins/window'

export default {
	name: 'TheSidebar',
	components: {
		TheSidebarModal,
		TheSidebarDesktop,
		BaseList
	},
	mixins: [windowMixin],
  computed: {
    sidebar() {
      return [
        {
          label: this.$t('create-order'),
          icon: 'add',
          route: '/admin/orders/new'
        },
        {
          label: this.$t('orders'),
          icon: 'receipt',
          route: '/admin/orders',
        },
        {
          label: this.$t('history-orders'),
          icon: 'receipt',
          route: '/admin/orders/history',
        },
      ];
    }
  },
	data() {
		/*return {
			sidebar
		}*/
	},
	methods: {
		...mapActions({
			setSidebar: 'setSidebar'
		}),
		handleListClick(event) {
			if (!this.$_window_isDesktop && !event.children) {
				this.setSidebar(false)
			}
		}
	}
}
</script>
