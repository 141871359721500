<template>
  <div class="dashboard">

    <h2 class="page-title">{{ $t("orders") }}</h2>
    <div>
      <div class="dashboard__container">
        <div class="dashboard__content">

          <base-container :title="$t('created-orders')">
            <div class="tw-pt-4">
              <Vue3EasyDataTable
                  :headers="newHeader"
                  :items="driverSearchOrders"
                  :rowsPerPageMessage="$t('rows-per-page')"
                  :emptyMessage="$t('no-avaliable-data')"
              >
                <template #item-operation="item">
                  <div class="tw-flex tw-items-center">
                    <img
                        width="24"
                        src="@/assets/img/delete.png"
                        class="tw-cursor-pointer tw-mr-2"
                        @click="orderCancel(item)"
                    />
                    <img
                        width="22"
                        src="@/assets/img/message.png"
                        class="tw-cursor-pointer"
                        @click="orderChat(item)"
                    />
                  </div>
                </template>
              </Vue3EasyDataTable>
            </div>

          </base-container>

          <base-container :title="$t('transporting-orders')">

            <div class="tw-pt-4">
              <Vue3EasyDataTable
                  :headers="taxionwayHeader"
                  :items="taxionwayOrders"
                  :rowsPerPageMessage="$t('rows-per-page')"
                  :emptyMessage="$t('no-avaliable-data')"
              >
                <template #item-operation="item">
                  <div class="tw-flex">
                    <img
                        width="22"
                        src="@/assets/img/message.png"
                        class="tw-cursor-pointer"
                        @click="orderChat(item)"
                    />
                  </div>
                </template>
              </Vue3EasyDataTable>
            </div>

          </base-container>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'
import {restPost} from "@/scripts/rest";
import config from "../scripts/config.js";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {printOrderType} from "@/scripts/helper";

export default {
  name: 'DashboardView',
  components: {
    BaseContainer,
    Vue3EasyDataTable
  },
  computed: {
    upcomming() {
      return this.$t('upcomming')
    },
    express() {
      return this.$t('express')
    },
    newHeader() {
      return [
        {text: this.$t('project'), value: "project", sortable: true},
        {text: this.$t('type'), value: "type", sortable: true},
        {text: this.$t('customer-name'), value: "customer_name", sortable: true},
        {text: this.$t('customer-phone'), value: "customer_phone", sortable: true},
        {text: this.$t('start'), value: "start", sortable: true},
        {text: this.$t('end'), value: "end", sortable: true},
        {text: this.$t('price'), value: "price", sortable: true},
        {text: this.$t('actions'), value: "operation"},
      ];
    },
    taxionwayHeader() {
      return [
        {text: this.$t('project'), value: "project", sortable: true},
        {text: this.$t('type'), value: "type", sortable: true},
        {text: this.$t('customer-name'), value: "customer_name", sortable: true},
        {text: this.$t('customer-phone'), value: "customer_phone", sortable: true},
        {text: this.$t('start'), value: "start", sortable: true},
        {text: this.$t('end'), value: "end", sortable: true},
        {text: this.$t('driver-name'), value: "driver_name", sortable: true},
        {text: this.$t('driver-phone'), value: "driver_phone", sortable: true},
        {text: this.$t('driver-car'), value: "driver_car", sortable: true},
        {text: this.$t('price'), value: "price", sortable: true},
        {text: this.$t('actions'), value: "operation"},
      ];
    }
  },
  data() {
    return {
      driverSearchOrders: [],
      plannedOrders: [],
      taxionwayOrders: [],
    }
  },
  created() {
    this.ordersBeat();
    this.loadDriverSearchOrders();
    this.orderDetail();


  },
  methods: {
    ordersBeat: function () {
      clearInterval(this.beatInterval);
      this.beatInterval = setInterval(() => {
        this.loadDriverSearchOrders();
      }, config.SERVER_BEAT_CURRENT_INTERVAL);
    },

    loadDriverSearchOrders: function () {
      let thisElement = this;
      restPost("/customer/account/beat", {})
          .then((response) => {
            //console.log(response);
            thisElement.driverSearchOrders = [];
            thisElement.taxionwayOrders = [];
            //console.log(response.data);
            if (response.data && response.data.beatData.orders) {
              for (let i in response.data.beatData.orders) {
                let order = response.data.beatData.orders[i];
                let orderStatus = order.orderStatus;

                if (orderStatus === 'new') {
                  let orderRow = {
                    'project': order.orderProject,
                    'type': printOrderType(order, this.express, this.upcomming),
                    'customer_name': order.orderCompanyCustomerName,
                    'customer_phone': order.orderCompanyCustomerPhone,
                    'start': order.orderStartLocName,
                    'end': order.orderEndLocName,
                    'price': order.ridePriceDuringValue + ' ' + order.ridePriceCurrency,
                    'id': order.orderId,
                  };

                  thisElement.driverSearchOrders.push(orderRow);
                }

                if (orderStatus === 'taxionway') {
                  //console.log(order);
                  let orderRow = {
                    'project': order.orderProject,
                    'type': printOrderType(order, this.express, this.upcomming),
                    'customer_name': order.orderCompanyCustomerName,
                    'customer_phone': order.orderCompanyCustomerPhone,
                    'start': order.orderStartLocName,
                    'end': order.orderEndLocName,
                    'driver_name': order.driverName,
                    'driver_phone': order.driverPhone,
                    'driver_car': order.carName,
                    'price': order.ridePriceTotalValue + ' ' + order.priceCurrencyCode,
                    'id': order.orderId,
                  };

                  thisElement.taxionwayOrders.push(orderRow);
                }
              }
            }

            if (response.data && response.data.beatData.timeorders.data) {
              for (let i in response.data.beatData.timeorders.data) {
                let order = response.data.beatData.timeorders.data[i];
                //console.log(order)
                let orderRow = {
                  'project': order.orderProject,
                  'type': printOrderType(order, this.express, this.upcomming),
                  'customer_name': order.orderCompanyCustomerName,
                  'customer_phone': order.orderCompanyCustomerPhone,
                  'start': order.orderStartLocName,
                  'end': order.orderEndLocName,
                  'price': '-',
                  'id': order.orderId,
                };

                thisElement.driverSearchOrders.push(orderRow);
              }
            }
          });
    },
    orderCancel: function (item) {

      if (!item.id && item.id <= 0) {
        return false;
      }

      restPost("/rides/orderCancel ", {orderId: item.id})
          .then((response) => {
            if (response.data.status === 1) {
              this.loadCurrentOrders();
            }
          });
    },
    orderChat: function (item) {
      this.$router.push({ name: 'orders-chat', params:{orderId : item.id}})
    },
    orderDetail: function () {
      restPost("/courierApi/orderStatus", {orderId: '259489'})
          .then((/*response*/) => {
            //console.log(response);
          });
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard__map {
  height: 300px;
}

.dashboard__inforgraphics {
  display: flex;

  .dashboard__graph {
    width: 50%;
    margin-right: 10px;
  }

  .dashboard__boxes {
    width: 50%;
    margin-left: 10px;

    .dashboard__box-set {
      display: flex;
      width: 100%;
      height: fit-content;
      margin-bottom: 10px;

      .dashboard__info-box {
        width: 50%;

        &--left {
          margin-right: 5px;
        }

        &--right {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
