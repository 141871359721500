const sidebar = {
	state: {
		opened: true
	},

	getters: {
		isSidebarOpened: (state) => state.opened
	},

	mutations: {
		SET_SIDEBAR(state, bool) {
			state.opened = bool
		}
	},

	actions: {
		setSidebar({ commit }, bool) {
			commit('SET_SIDEBAR', bool)
		}
	}
}

export default sidebar
