<template>
	<div v-if="init">
		<transition name="sidebar">
			<aside v-if="isSidebarOpened" class="mdc-drawer mdc-drawer--modal">
				<the-sidebar-user />
				<nav class="mdc-drawer__content">
					<slot />
				</nav>
			</aside>
		</transition>

		<transition name="fade">
			<div
				v-if="isSidebarOpened"
				class="mdc-drawer-scrim"
				@click="handleScrim"
			/>
		</transition>
	</div>
</template>

<script>
import TheSidebarUser from '@/components/TheSidebarUser'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'TheSidebarModal',
	components: {
		TheSidebarUser
	},
	data() {
		return {
			init: false
		}
	},
	computed: {
		...mapGetters({
			isSidebarOpened: 'isSidebarOpened'
		})
	},
	watch: {
		// whenever question changes, this function will run
		isSidebarOpened(newValue) {
			if (newValue) {
				document.body.classList.add('body--overflow')
			} else {
				document.body.classList.remove('body--overflow')
			}
		}
	},
	mounted() {
		this.setSidebar(false)
		this.init = true
	},
	unmounted() {
		document.body.classList.remove('body--overflow')
	},
	methods: {
		...mapActions({
			setSidebar: 'setSidebar'
		}),
		handleScrim() {
			this.setSidebar(false)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
.mdc-drawer {
	z-index: 9;
	margin-top: -$header-height;
}

.mdc-drawer--modal {
	display: flex;
}

.mdc-drawer-scrim {
	display: block;
	z-index: 8;
}

// Modal transition
.sidebar-enter-active,
.sidebar-leave-active {
	transition: $transition;
}

.sidebar-enter-from,
.sidebar-leave-to {
	margin-left: -$sidebar-width;
}

// Scrim transition
.fade-enter-active,
.fade-leave-active {
	transition: $transition;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
