export default {
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "created-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created orders"])},
  "create-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
  "created-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order created"])},
  "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
  "start-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing.."])},
  "start-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start location"])},
  "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "phone-sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender phone"])},
  "phone-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient phone"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "note-sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender note"])},
  "note-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient note"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "express-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express transport"])},
  "destination-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination location"])},
  "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and Surname"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "customer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
  "customer-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer phone"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "driver-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver name"])},
  "driver-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver phone"])},
  "driver-car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver car"])},
  "rows-per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows per page:"])},
  "no-avaliable-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Available Data"])},
  "transporting-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporting orders"])},
  "history-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History orders"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "chat-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the message to the courier"])},
  "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
  "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up time"])},
  "pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up date"])},
  "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
  "is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required filed"])},
  "upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express"])},
  "select-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time"])},
  "express-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express orders"])},
  "upcomming-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal orders"])}
}