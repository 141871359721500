<template>
	<div class="footer">
		<div class="footer__content">@ HOPIN s.r.o</div>
	</div>
</template>

<script>
export default {
	name: 'TheFooter'
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.footer {
  width: 100%;
  color: #8d96ab;
  background-color: $footer-background;
  padding: 20px 5px;
  box-sizing: border-box;

  .footer__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
</style>
