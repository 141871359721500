<template>
	<div class="the-sidebar-user">
		<!-- TODO: user real user image -->
<!--		<img
			class="the-sidebar-user__image"
			src="@/assets/img/default-avatar2.png"
			alt="user_image"
		/>-->
		<div class="the-sidebar-user__info">
			<p class="the-sidebar-user__name">{{ name }} {{ surname }}</p>
      <span class="tw-block tw-text-small tw-mb-2">{{ company }}</span>
			<div class="the-sidebar-user__icons tw-flex tw-items-center">
				<span
					class="the-sidebar-user__logout material-icons tw-cursor-pointer tw-mr-2"
					@click="handleLogout"
				>
					power_settings_new
				</span>
        <svg @click="handleLocaleChange('en')" class="svg-icon tw-cursor-pointer tw-mr-2" width="21" height="16" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20.6" height="16" rx="2.4" fill="#fff"></rect>
          <mask id="a" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="24">
            <rect width="33.6" height="24" rx="2.4" fill="#fff"></rect>
          </mask>
          <g mask="url(#a)">
            <path fill="#0A17A7" d="M0 0h33.6v24H0z"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.54-2.3 12.8 7.372V-1.6h8v8.972L35.139-2.3l1.79 2.653L25.59 8H33.6v8H25.59l11.337 7.647-1.79 2.653L20.8 16.628V25.6h-8v-8.972L-1.54 26.3l-1.789-2.653L8.008 16H0V8h8.008L-3.329.353l1.79-2.653z" fill="#fff"></path>
            <path d="m17.602 11.199 15.198-10M18.015 12.837 35.4 24.6M16.2 11.4-2.205-.805M16.2 12.6l-18 12" stroke="#DB1F35" stroke-width=".8" stroke-linecap="round"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.4h14.4V24h4.8v-9.6h14.4V9.6H19.2V0h-4.8v9.6H0v4.8z" fill="#E6273E"></path>
          </g>
        </svg>
        <svg @click="handleLocaleChange('sk')" class="svg-icon tw-cursor-pointer" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_403_4555)">
            <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H18C19.6569 0.5 21 1.84315 21 3.5V12.5C21 14.1569 19.6569 15.5 18 15.5H3C1.34315 15.5 0 14.1569 0 12.5V3.5Z" fill="#1A47B8"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5H21V15.5H0V10.5Z" fill="#F93939"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5H21V5.5H0V0.5ZM9.93 10.5H10V5.5H3V10.5H3.07C3.024 10.662 3 10.83 3 11C3 12.38 6.5 13.5 6.5 13.5C6.5 13.5 10 12.38 10 11C10 10.83 9.976 10.662 9.93 10.5Z" fill="white"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 10.5V4.5H4V10.5C4 11.605 6.5 12.5 6.5 12.5C6.5 12.5 9 11.605 9 10.5Z" fill="#F93939"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.5V7.5H5V6.5H6V5.5H7V6.5H8V7.5H7V8.5H8.5V9.5H7V10.5H6V9.5H4.5V8.5H6Z" fill="white"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 12.4998C6.5 12.4998 9 11.6048 9 10.4998C9 9.39483 8 9.49983 6.5 10.4998C5 9.49983 4 9.39483 4 10.4998C4 11.6048 6.5 12.4998 6.5 12.4998Z" fill="#1A47B8"></path>
          </g>
          <defs>
            <clipPath id="clip0_403_4555">
              <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H18C19.6569 0.5 21 1.84315 21 3.5V12.5C21 14.1569 19.6569 15.5 18 15.5H3C1.34315 15.5 0 14.1569 0 12.5V3.5Z" fill="white"></path>
            </clipPath>
          </defs>
        </svg>
			</div>
		</div>
	</div>
</template>

<script>
import {logoutUser} from "@/scripts/helper";
import {restPost} from "@/scripts/rest";
import i18n from "@/i18n";

export default {
	name: 'TheSidebarUser',
  data(){
    return {
      name: '',
      surname: '',
      company: '',

    }
  },
  created() {
    this.loadProfileData();

  },
  methods: {
		handleLogout() {
			logoutUser();
		},
    handleLocaleChange(locale) {
      i18n.global.locale.value = locale;
    },
    loadProfileData() {
      restPost("/customer/profile/read", {})
          .then((response) =>
          {
            if(response.data.profileData) {
              let userData = response.data.profileData;
              this.name = userData.userName;
              this.surname = userData.userSurname;
              this.company = userData.companyName;
            }
          });
    }
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/mixins";

.the-sidebar-user {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;

	.the-sidebar-user__image {
		max-height: 72px;
		border-radius: 50%;
		background: white;
		padding: 2px;
		border: 1px solid rgba(52, 73, 94, 0.44);
	}

	.the-sidebar-user__info {
		padding-left: 10px;

		p {
			margin: 10px 0 0;
			font-size: 20px;
		}

		.the-sidebar-user__icons {
			span {
				@include no-select;
			}
		}
	}
}
</style>
