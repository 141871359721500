const translations = {
	state: {
		currentLanguageCode: 'sk'
	},

	getters: {
		getCurrentLanguageCode: (state) => state.currentLanguageCode
	},

	mutations: {
		SET_CURRENT_LANGUAGE_CODE(state, code) {
			state.currentLanguageCode = code
		}
	},

	actions: {
		setCurrentLanguageCode({ commit }, code) {
			commit('SET_CURRENT_LANGUAGE_CODE', code)
		}
	}
}

export default translations
