<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style lang="scss">
@import "./assets/scss/variables";

.app {
  height: 100%;
  width: 100%;
  background-color: $main-background;
}
</style>
