// Breakpoints: https://material.io/design/layout/responsive-layout-grid.html#breakpoints

const windowMixin = {
	data() {
		return {
			width: 0,
			height: 0
		}
	},
	computed: {
		$_window_isMobile() {
			return this.width < 600
		},
		$_window_isTabled() {
			return this.width >= 600 && this.width < 1240
		},
		$_window_isDesktop() {
			return this.width >= 1240
		}
	},
	created() {
		window.addEventListener('resize', this.$_window_handleResize)
		this.$_window_handleResize()
	},
	unmounted() {
		window.removeEventListener('resize', this.$_window_handleResize)
	},
	methods: {
		$_window_handleResize() {
			this.width = window.innerWidth
			this.height = window.innerHeight
		}
	}
}

export default windowMixin
