<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-flex-col-reverse tw-mb-4">
      <div v-for="(message, index) in this.messages" :key="`message-${index}`"
           class="message tw-flex tw-flex-col tw-mb-4" :class="[message.msgSender !== 'i' ? 'message-courier' : '']">
        <div class="message__inner tw-p-4 tw-max-w-3xl" :class="[message.msgSender !== 'i' ? 'tw-text-right' : 'tw-text-left']">
          <div class="tw-font-bold message__author">{{ message.msgSender !== 'i' ? $t('driver') : this.name + ' ' + this.surname }}</div>
          <div class="tw-text-xs tw-mb-2">{{ timestampToDate(message.dateCreatedTimeStamp) }}</div>
          <div class="message__message">{{ message.msgText }}</div>
        </div>
      </div>
    </div>
    <form  v-on:submit.prevent="sendMessage">
      <ui-form class="tw-flex tw-items-center" nowrap>
        <template #default="{ actionClass }">
          <ui-textfield class="tw-w-full tw-mr-4" type="text" required v-model="newMessage" outlined></ui-textfield>
          <ui-form-field :class="actionClass">
            <ui-button class="message-send" nativeType="submit" raised>
              <svg height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.56 122.88">
                <title>send</title>
                <path fill="#FEE100"
                      d="M2.33,44.58,117.33.37a3.63,3.63,0,0,1,5,4.56l-44,115.61h0a3.63,3.63,0,0,1-6.67.28L53.93,84.14,89.12,33.77,38.85,68.86,2.06,51.24a3.63,3.63,0,0,1,.27-6.66Z"/>
              </svg>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
    </form>
  </div>
</template>

<script>

import {restPost} from "@/scripts/rest";
import {MDCTextField} from "@material/textfield";
import config from "@/scripts/config";

export default {
  name: "ChatOrder",
  props: {
    orderId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      name: '',
      surname: '',
    }
  },
  mounted() {
    [].map.call(document.querySelectorAll('.mdc-text-field'), function (el) {
      return new MDCTextField(el);
    });
  },
  created() {
    this.messagesBeat();
    this.loadProfileData()
    this.getMessages();
  },
  methods: {
    loadProfileData() {
      restPost("/customer/profile/read", {})
          .then((response) =>
          {
            if(response.data.profileData) {
              let userData = response.data.profileData;
              //console.log(response.data.profileData);
              this.name = userData.userName;
              this.surname = userData.userSurname;
            }
          });
    },
    messagesBeat: function () {
      clearInterval(this.beatInterval);
      this.beatInterval = setInterval(() => {
        this.getMessages();
      }, config.SERVER_BEAT_HISTORY_INTERVAL);
    },
    getMessages: function () {
      /*restPost("/customer/messages/readList", {
        listSize: 28,
        listPage: 1,
        serviceCode: 'taxi',
        subserviceCode: 'standard',
        userType: 'c'
      })
          .then((response) => {
            console.log('get messsages ', response)
          });*/
      restPost("/customer/messages/read", {
        listSize: 14,
        listPage: 1,
        serviceCode: 'taxi',
        subserviceCode: 'standard',
        streamId: 'o' + this.orderId,
      }).then((response) => {
        //console.log(response)
        this.messages = response.data.messagesSpecific;
      });
    },
    sendMessage: function () {
      restPost("/customer/messages/send", {
        serviceCode: 'taxi',
        subserviceCode: 'standard',
        streamId: 'o' + this.orderId,
        msgText: this.newMessage,
      })
          .then((response) => {
            if(response.status === 200) {
              this.messages.unshift({
                msgText : this.newMessage,
                msgSender: 'i'
              })
              this.newMessage = '';
            }
            //console.log('send messsage ', response)
          });
    },
    timestampToDate: function (timestmap) {
      let date = new Date(timestmap);
      return date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
    }
  }

}
</script>

<style lang="scss" scoped>
.message {
  align-items: flex-start;

  &__inner {
    background: var(--bg-header);
  }

  &-courier {
    align-items: flex-end;

    .message__inner {
      background: var(--text-color);
      color: #fff;
    }
  }

  &-send {
    background-color: transparent !important;
    box-shadow: none;
    height: 48px;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }
}

</style>
