<template>
  <div class="login-box tw-flex tw-justify-center tw-items-center tw-flex-col tw-m-auto tw-px-12 tw-pt-12 tw-pb-10 tw-rounded-lg tw-bg-white tw-mt-10 tw-lg:mt-20 tw-box-border">
    <img class="login-box__logo tw-w-full tw-mb-10" alt="logo" src="@/assets/img/logo.svg">
    <InfoMessage v-if="infoMessageValue" v-bind:messageValue="infoMessageValue" v-bind:messageClass="infoMessageClass"/>
    <form class="login-form tw-flex tw-flex-col tw-w-full" @submit.prevent="doLogin">
      <label class="mdc-text-field mdc-text-field--outlined tw-mb-4">
          <span class="mdc-notched-outline">
            <span class="mdc-notched-outline__leading"></span>
            <span class="mdc-notched-outline__notch">
              <span class="mdc-floating-label" id="email-label">{{ 'E-mail' }}</span>
            </span>
            <span class="mdc-notched-outline__trailing"></span>
          </span>
        <input type="email" v-model="email" required class="mdc-text-field__input" aria-labelledby="email-label">
      </label>
      <label class="mdc-text-field mdc-text-field--outlined tw-mb-4">
          <span class="mdc-notched-outline">
            <span class="mdc-notched-outline__leading"></span>
            <span class="mdc-notched-outline__notch">
              <span class="mdc-floating-label" id="password-label">{{ 'Password' }}</span>
            </span>
            <span class="mdc-notched-outline__trailing"></span>
          </span>
        <input class="mdc-text-field__input" v-model="password" type="password" aria-labelledby="password-label" required minlength="6">
      </label>

      <button type="submit" class="mdc-button mdc-button--raised">
        <span class="mdc-button__label">{{ 'Login' }}</span>
      </button>
    </form>
  </div>
</template>

<script>
import InfoMessage from "@/components/InfoMessage";
import router from "@/router/index";
import {restPost, hashString, accountCreate} from "@/scripts/rest";
import {isUserLogged, redirectIfLogged} from "@/scripts/helper";
import {MDCTextField} from '@material/textfield';

export default {
  name: "LoginView",
  components: {
    InfoMessage,
  },
  data() {
    return {
      email: null,
      password: null,
      infoMessageValue: null,
      infoMessageClass: 'error'
    }
  },
  created() {
    if(!isUserLogged()) {
      accountCreate();
    }
    redirectIfLogged({name: "orders"});
  },
  mounted() {
    [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
      return new MDCTextField(el);
    });
  },
  methods:
      {
        doLogin: function () {
          let thisElement = this;
          let formData = {};

          thisElement.infoMessageValue = "";

          formData.userLogin = this.email;
          formData.userPass = hashString({pass: this.password, salt: this.email});
          restPost("/general/account/login", formData)
              .then((response) => {
                if (response.data && response.data.status === 1) {
                  localStorage.setItem("userKey", response.data.userData.userKey);
                  localStorage.setItem("userId", response.data.userData.userId);
                  localStorage.setItem("logged", response.data.userData.userId);
                  router.push({name: "orders"});
                } else {
                  thisElement.infoMessageValue = "Wrong e-mail, or password!";
                }
              });
         }
      }
}
</script>


<style lang="scss">
  .login {
    &-box {
      width: 500px;
      &__logo {
        max-width:300px;
      }
    }
  }
</style>
