<template>
  <div class="new-order dashboard">
    <h2 class="page-title">{{ $t("create-order") }}</h2>
    <div>
      <div class="dashboard__container">
        <div class="dashboard__content">

          <base-container :title="$t('order-details')">
            <CreateOrder :key="key1"></CreateOrder>
          </base-container>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CreateOrder from "@/components/CreateOrder";
import BaseContainer from '@/components/BaseContainer'

export default {
  name: "CreateOrderView",
  components: {CreateOrder, BaseContainer},
  data() {
    return {
      key1: 0,
    };
  },
  mounted() {
    this.$nextTick(function () {
      setTimeout(function() {
        this.key1 += 1;
      }, 100);
    });
  },
  methods: {
    forceRerender() {
      this.key1 += 1;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
