const device = {
	state: {
		type: null,
		subType: null,
		osVer: null,
		model: null,
		uuid: null,
		pushToken: null,
		osv: null,
		isRetina: false,
		animationLevel: 0,
		isCordova: false
	},

	getters: {
		getDevice: (state) => state
	},

	mutations: {
		// object: {isRetina: true} will set isRetina to true
		SET_DEVICE(state, obj) {
			for (const key in obj) {
				state[key] = obj[key]
			}
		}
	},

	actions: {
		initDevice({ commit }, deviceObj) {
			commit('SET_DEVICE', deviceObj)
		}
	}
}

export default device
