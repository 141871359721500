<template>
	<div class="site-header">
		<div class="header__title">
			<img src="@/assets/img/logo.svg" alt="Hopin logo" />
		</div>
		<span class="header__icon material-icons" @click="handleSidebar">
			menu
		</span>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'TheHeader',
	computed: {
		...mapGetters({
			isSidebarOpened: 'isSidebarOpened'
		})
	},
	methods: {
		...mapActions({
			setSidebar: 'setSidebar'
		}),
		handleSidebar() {
			this.setSidebar(!this.isSidebarOpened)
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/variables";
@import "../assets/scss/mixins";

.site-header {
  background: $header-background;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  position: fixed;
  top: 0;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 7;
  .header__title {
    margin-right: 20px;
  }

  .header__icon {
    @include no-select;
    margin-left: 20px;
    cursor: pointer;
  }
}

</style>
