<template>
  <the-layout>
    <router-view  :key="$route.fullPath"></router-view>
  </the-layout>
</template>

<script>
import {assertLoggedUser, isUserLogged} from "@/scripts/helper";
import TheLayout from '@/components/TheLayout'

export default {
  name: 'AdminView',
  components: {
    TheLayout,
  },
  created() {
    if(!isUserLogged()) {
      assertLoggedUser();
    }
  },
}
</script>
