<template>
	<div class="mdc-form-field">
		<div
			class="mdc-checkbox"
			:class="{ 'mdc-checkbox--disabled': disabled }"
		>
			<input
				:id="getId"
				type="checkbox"
				class="mdc-checkbox__native-control"
				:checked="modelValue"
				:disabled="disabled"
				:indeterminate="indeterminate"
				:data-indeterminate="indeterminate"
				@input="handleClick"
			/>
			<div class="mdc-checkbox__background">
				<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
					<path
						class="mdc-checkbox__checkmark-path"
						fill="none"
						d="M1.73,12.91 8.1,19.28 22.79,4.59"
					/>
				</svg>
				<div class="mdc-checkbox__mixedmark" />
			</div>
			<div class="mdc-checkbox__ripple" />
		</div>
		<label v-if="checkboxLabel" :for="getId">
			{{ checkboxLabel }}
		</label>
	</div>
</template>

<script>
import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'
import { uuid } from '@/modules/uuid'

export default {
	name: 'BaseCheckbox',
	props: {
		checkboxLabel: {
			type: String,
			default: null
		},
		modelValue: {
			type: Boolean,
			default: false
		},
		indeterminate: {
			type: Boolean,
			default: false
		},
		customId: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			id: null
		}
	},
	computed: {
		getId() {
			return this.customId || this.id
		}
	},
	created() {
		this.id = uuid()
	},
	mounted() {
		const mdcFormField = this.$el
		const mdcCheckbox = mdcFormField.querySelector('.mdc-checkbox')

		const checkbox = new MDCCheckbox(mdcCheckbox)
		const formField = new MDCFormField(mdcFormField)
		formField.input = checkbox
	},
	methods: {
		handleClick(e) {
			this.$emit('update:modelValue', e.target.checked)
		}
	}
}
</script>
