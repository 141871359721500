<template>
  <InfoMessage v-if="infoMessageValue" v-bind:messageValue="infoMessageValue" v-bind:messageClass="infoMessageClass"/>
  <div class="newOrder">
    <ui-alert v-if="messages.length" state="error">
      <ul>
        <li v-for="(message, index) in messages" :key="index">{{ message }}</li>
      </ul>
    </ui-alert>
    <ui-form novalidate nowrap>
      <template #default="{ actionClass }">
        <ui-grid class="newOrder-grid">
          <ui-grid-cell>
            <h3>{{ $t('sender') }}</h3>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('start-location') }}:</label>
              <ui-autocomplete
                  fullwidth
                  v-model="newOrderData.start"
                  outlined
                  :source="locationsStart"
                  :placeholder="$t('start-typing')"
                  delay="500"
                  remote
                  auto-focus
                  @search="geoAutoCompleteStart"
                  @update:modelValue="updateOrderStart"
              ></ui-autocomplete>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('e-mail') }}:</label>
              <ui-textfield class="tw-w-full" placeholder="example@domain.com" input-type="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" v-model="newOrderData.email"
                            outlined>
              </ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('phone') }}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.phone1" outlined>
                +421XXXXXXXXX
              </ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('note') }}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.note" outlined
                            input-type="textarea" rows="8" cols="25"></ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('project') }}:</label>
              <ui-autocomplete
                  autofocus
                  fullwidth
                  v-model="newOrderData.project"
                  outlined
                  :source="projects"
                  :placeholder="$t('start-typing')"
                  delay="300"
                  remote
                  auto-focus
                  @search="initProjects"
              ></ui-autocomplete>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('pickup-date') }}:</label>
              <ui-datepicker
                  autofocus
                  fullwidth
                  outlined
                  v-model="newOrderData.date"
                  @update:modelValue="onDateUpdate"
                  :config="{
                      defaultDate: 'today',
                      minDate: 'today',
                    }"
                  :placeholder="$t('select-date')"
                  toggle
                  clear
              ></ui-datepicker>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('pickup-time') }}:</label>
              <ui-select
                  outlined
                  fullwidth
                  :defaultLabel="$t('select-time')"
                  v-model="newOrderData.timeSlot"
                  :options="timeSlots"
              >
              </ui-select>
            </ui-form-field>

          </ui-grid-cell>

          <ui-grid-cell>
            <h3>{{ $t('recipient')}}</h3>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('destination-location')}}:</label>
              <ui-autocomplete
                  fullwidth
                  v-model="newOrderData.end"
                  outlined
                  :source="locationsEnd"
                  :placeholder="$t('start-typing')"
                  delay="500"
                  remote
                  auto-focus
                  @search="geoAutoCompleteEnd"
                  @update:modelValue="updateOrderEnd"
              ></ui-autocomplete>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('name')}}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.name" outlined>
              </ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('surname')}}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.surname" outlined>
              </ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('phone') }}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.phone2" outlined>
                +421XXXXXXXXX
              </ui-textfield>
            </ui-form-field>

            <ui-form-field class="tw-mb-4 tw-w-full">
              <label>{{ $t('note') }}:</label>
              <ui-textfield class="tw-w-full" type="text" v-model="newOrderData.note2" outlined
                            input-type="textarea" rows="8" cols="25"></ui-textfield>
            </ui-form-field>

          </ui-grid-cell>
        </ui-grid>
        <ui-grid class="newOrder-grid">
          <ui-grid-cell>
            <ui-form-field :class="actionClass">
              <label></label>
              <ui-button nativeType="submit" @click="createNewOrder" raised>{{ $t('submit') }}</ui-button>
            </ui-form-field>
          </ui-grid-cell>
        </ui-grid>
      </template>
    </ui-form>
  </div>

</template>

<script>
/* eslint-disable */
import InfoMessage from "@/components/InfoMessage";
import {restPost} from "@/scripts/rest";
import {MDCTextField} from "@material/textfield";
import { useValidator } from 'balm-ui';
import { helpers } from 'balm-ui';
export default {
  name: "CreateOrder",
  components: {InfoMessage},
  computed: {
    express() {
      return this.$t('express')
    },
    requiredRule() {
      return {
        validate(value) {
          return !helpers.isEmpty(value);
        },
        message: '%s ' + this.$t('is-required')
      }
    },
    validations() {
      return [
        {
          key: 'start',
          label: this.$t('start-location'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'end',
          label: this.$t('destination-location'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'email',
          label: this.$t('e-mail'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'name',
          label: this.$t('name'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'surname',
          label: this.$t('surname'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'phone1',
          label: this.$t('phone-sender'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'phone2',
          label: this.$t('phone-recipient'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'note',
          label: this.$t('note-sender'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'note2',
          label: this.$t('note-recipient'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'date',
          label: this.$t('pickup-date'),
          validator: 'required',
          required: this.requiredRule,
        },
        {
          key: 'timeSlot',
          label: this.$t('pickup-time'),
          validator: 'required',
          required: this.requiredRule,
        },
      ];
    }
  },
  data() {
    return {
      validator: useValidator(),
      messages: [],
      locationsStart: [],
      locationsEnd: [],
      projects: [],
      timeSlots: [],
      newOrderData:
          {
            name: '',
            surname: '',
            start: '',
            startGps: '',
            end: '',
            endGps: '',
            phone1: '',
            phone2: '',
            note: '',
            note2: '',
            project: '',
            date: '',
            timeSlot: '',
            email: '',
          },
      payMethods: null,
      infoMessageValue: null,
      infoMessageClass: null
    }
  },
  mounted() {
    [].map.call(document.querySelectorAll('.mdc-text-field'), function (el) {
      return new MDCTextField(el);
    });
  },
  created() {
    this.initProjects();
    this.initTimeSlots();
    this.readPaymentMethods();
  },
  methods: {
    async geoAutoCompleteStart(search) {
      restPost("/geo/autocomplete", {
        searchString: search,
        lat: '48.2',
        lng: '17.1'
      })
          .then((response) => {
            if (response.data.geoData) {
              let choices = [];
              response.data.geoData.forEach((item) => {
                choices.push({
                  'label': item.displayName,
                  'value': item
                });
              });
              this.locationsStart = choices;
            }
          });
    },
    async geoAutoCompleteEnd(search) {
      restPost("/geo/autocomplete", {
        searchString: search,
        lat: '48.2',
        lng: '17.1'
      })
          .then((response) => {
            //console.log(response.data);
            if (response.data.geoData) {
              let choices = [];
              response.data.geoData.forEach((item) => {
                choices.push({
                  'label': item.displayName,
                  'value': item
                });
              });
              this.locationsEnd = choices;
            }
          });
    },
    updateOrderStart(item) {
      let searchResults = this.locationsStart.find(element => element.label === item);

      if (searchResults) {
        this.newOrderData.start = searchResults.value.displayName;
        this.newOrderData.startGps = {lat: searchResults.value.lat, lng: searchResults.value.lng}
      }
    },
    updateOrderEnd(item) {
      let searchResults = this.locationsEnd.find(element => element.label === item);

      if (searchResults) {
        this.newOrderData.end = searchResults.value.displayName;
        this.newOrderData.endGps = {lat: searchResults.value.lat, lng: searchResults.value.lng}
      }
    },
    createNewOrder: function () {
      this.validator.set(this.validations)
      let result = this.validator.validate(this.newOrderData);
      let { valid, messages } = result;
      this.messages = messages;
      this.infoMessageValue = null;
      this.infoMessageClass = null;

      if (valid) {
        let orderData = this.grabOrderData();
        console.log('order data', orderData)
        //let error = this.checkOrderData(orderData);


        let thisElement = this;
        restPost("/rides/orderCreate", orderData)
            .then((response) => {
              thisElement.newOrderData =
                  {
                    start: '',
                    startGps: '',
                    end: '',
                    endGps: '',
                    phone1: '',
                    phone2: '',
                    name: '',
                    note: '',
                    note2: '',
                    project: '',
                    date: '',
                    timeSlot: '',
                    email: '',
                  };

              this.infoMessageValue = this.$t('created-order');
              this.infoMessageClass = 'success';

              //console.log("createNewOrder response: ", response);
            });
      }
    },
    checkOrderData: function (orderData) {
      let error = null;

      if (orderData.paymentId === 0) {
        error = "Missing COMPANY pay method";
      }

      return error;
    },
    initProjects: function () {
      restPost("/customer/profile/projects/read", {})
          .then((response) => {
            if (response.data && response.data.projectsData) {
              let projects = [];
              response.data.projectsData.forEach((item) => {
                projects.push({
                  'label': item.projectName,
                  'value': item.projectName,
                })
              })
              this.projects = projects;
            }
          });
    },
    initTimeSlots: function() {
      restPost("/courierApi/timeSlots", {})
          .then((response) => {
            let timeSLots = [{
              label: this.express,
              value: 'express',
              //disabled: true
            }];
            if(response.data.data) {
              response.data.data.forEach((item) => {
                if(item.active === 1 && item.hour >= 0 && item.minute >= 0) {
                  timeSLots.push({
                    'label': item.hour + ":" + item.minute.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
                    'value': item.hour + ":" + item.minute.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
                    disabled: true
                  })
                }

              })
            }
            this.timeSlots = timeSLots
          });
    },
    readPaymentMethods: function () {
      let thisElement = this;
      restPost("/customer/profile/payments/read", {})
          .then((response) => {
            if (response.data && response.data.paymentsData) {
              thisElement.payMethods = response.data.paymentsData;
            }
          });
    },
    grabOrderData: function () {
      let orderSelectedStartTimeFormatted = {};
      let today = new Date();
      orderSelectedStartTimeFormatted['day'] = today.getUTCDate();
      orderSelectedStartTimeFormatted['month'] = today.getUTCMonth();
      orderSelectedStartTimeFormatted['year'] = today.getUTCFullYear();
      orderSelectedStartTimeFormatted['hour'] = today.getHours();
      orderSelectedStartTimeFormatted['minute'] = today.getMinutes();

      //let serviceCourierCode = 'deliveryCourierNow';
      let serviceCourierCode = 'deliveryCourierNowPortal';
      if(this.newOrderData.timeSlot !== 'express') {
        let time = this.newOrderData.timeSlot.split(':')
        let date = this.newOrderData.date.split('-')
        orderSelectedStartTimeFormatted['day'] = parseInt(date[2]);
        orderSelectedStartTimeFormatted['month'] = parseInt(date[1]) - 1;
        orderSelectedStartTimeFormatted['year'] = parseInt(date[0]);
        orderSelectedStartTimeFormatted['hour'] = parseInt(time[0]);
        orderSelectedStartTimeFormatted['minute'] = parseInt(time[1]);
        //serviceCourierCode = 'deliveryCourierLater';
        serviceCourierCode = 'deliveryCourierLaterPortal';
      }

      return {
        orderStartLocLat: this.newOrderData.startGps.lat,
        orderStartLocLng: this.newOrderData.startGps.lng,
        orderEndLocLat: this.newOrderData.endGps.lat,
        orderEndLocLng: this.newOrderData.endGps.lng,
        orderNote: this.newOrderData.note,
        orderNoteStart: this.newOrderData.note,
        orderNoteEnd: this.newOrderData.note2,
        orderProject: this.newOrderData.project,
        orderStartLocName: this.newOrderData.start,
        orderEndLocName: this.newOrderData.end,
        orderCompanyCustomerName: this.newOrderData.name + ' ' + this.newOrderData.surname,
        orderCompanyCustomerPhone: this.newOrderData.phone2,
        orderStartTime: orderSelectedStartTimeFormatted,
        serviceCourierCode: serviceCourierCode,
        contactStartPhone: this.newOrderData.phone1,
        contactEndPhone: this.newOrderData.phone2,
        contactStartEmail: this.newOrderData.email,

        orderCarCount: 1,
        serviceCode: 'taxi',
        subserviceCode: 'courier',
        orderSource: 'portal',

        paymentMethod: 'company',
        paymentId: this.getCompanyPayMethodId()
      }
    },
    getCompanyPayMethodId: function () {
      let payMethodId = 0;
      if (this.payMethods != null && this.payMethods.length > 0) {
        for (let i in this.payMethods) {
          if (this.payMethods[i].methodCode === 'company') {
            payMethodId = this.payMethods[i].methodId;
          }
        }
      }

      return payMethodId;
    },
    onDateUpdate: function(date) {
      this.checkDateTime()
    },
    checkDateTime: function() {
      if(!this.newOrderData.date) {
        return false;
      }

      this.newOrderData.timeSlot = '';
      let today = new Date()

      for (let i in this.timeSlots) {

        if(this.timeSlots[i].value === 'express') {
          continue
        }

        let date = new Date(this.newOrderData.date + ' ' + this.timeSlots[i].value)

        if(date.getTime() <= today.getTime()) {
          this.timeSlots[i]['disabled'] = true;
        } else {
          this.timeSlots[i]['disabled'] = false;
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.newOrder {
  &-grid {
    padding: 0 !important;
  }
}
</style>
