<template>
  <div class="new-order dashboard">
    <h2 class="page-title">{{ $t("chat-order") }}</h2>
    <div>
      <div class="dashboard__container">
        <div class="dashboard__content">

          <base-container>
            <ChatOrder :orderId="orderId" :key="key1"></ChatOrder>
          </base-container>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseContainer from '@/components/BaseContainer'
import ChatOrder from "@/components/ChatOrder.vue";

export default {
  name: "ChatOrderView",
  components: {BaseContainer, ChatOrder},
  data() {
    return {
      key1: 0,
      orderId: this.$route.params.orderId ?? 0
    };
  },
  mounted() {
    this.$nextTick(function () {
      setTimeout(function() {
        this.key1 += 1;
      }, 100);
    });
  },
  methods: {
    forceRerender() {
      this.key1 += 1;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
