<template>
	<ul class="mdc-deprecated-list">
		<base-list-item v-for="(item, index) in list" :key="`${item.label ||
		item}-${index}`" :data="item" :is-opened="getTopLevelRoute ===
		item.route" :sidebar="sidebar" :drag="drag"
		:checkbox="checkbox" :checkbox-id="checkboxId" @list-click="handleListClick"
		@checkbox="handleListCheckboxChange($event, index)" />
	</ul>
</template>

<script>
import BaseListItem from '@/components/BaseListItem'
import Sortable from 'sortablejs'

export default {
	name: 'BaseList',
	components: {
		BaseListItem
	},
	props: {
		/**
		 * List: array{}
		 * -------------------------
		 * label: string(required),
		 * children: array{}
		 * href: urlString,
		 * icon: icon name - https://fonts.google.com/icons
		 */
		list: {
			type: Array,
			required: true
		},
		/**
		 * Changes the design to fit the sidebar design
		 */
		sidebar: {
			type: Boolean,
			default: false
		},
		checkbox: {
			type: Boolean,
			default: false
		},
		drag: {
			type: Boolean,
			default: false
		},
		checkboxId: {
			type: String,
			default: null
		},
		sizeSmall: {
			type: Boolean,
			default: false
		}
	},
	emits: ['listClick', 'change'],
	computed: {
		getCurrentRoute() {
			return this.$route.path
		},
		getTopLevelRoute() {
			return `/${this.getCurrentRoute.split('/')[1]}`
		}
	},
	mounted() {
		const el = this.$el

		const that = this

		Sortable.create(el, {
			animation: 150,
			onEnd(evt) {
				that.handleReorder(evt)
			}
		})
	},
	methods: {
		handleListClick(data) {
			this.$emit('listClick', data)
		},
		handleReorder(event) {
			const reorderedArr = this.list.map((obj) => Object.assign({}, obj))

			const element = reorderedArr.splice(event.oldIndex, 1)[0]
			reorderedArr.splice(event.newIndex, 0, element)

			this.$emit('change', reorderedArr)
		},
		handleListCheckboxChange(event, index) {
			const newArray = this.list.map((obj) => Object.assign({}, obj))

			newArray[index].checkbox = event

			this.$emit('change', newArray)
		}
	}
}
</script>
