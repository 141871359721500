<template>
	<div v-if="init">
		<transition name="sidebar">
			<aside v-show="isSidebarOpened" class="mdc-drawer">
				<the-sidebar-user />
				<nav class="mdc-drawer__content">
					<slot />
				</nav>
			</aside>
		</transition>
	</div>
</template>

<script>
import TheSidebarUser from '@/components/TheSidebarUser'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'TheSidebarDesktop',
	components: {
		TheSidebarUser
	},
	data() {
		return {
			init: false
		}
	},
	computed: {
		...mapGetters({
			isSidebarOpened: 'isSidebarOpened'
		})
	},
	mounted() {
		this.setSidebar(true)
		this.init = true
	},
	methods: {
		...mapActions({
			setSidebar: 'setSidebar'
		})
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
.mdc-drawer {
	border: none;
}

.sidebar-enter-active,
.sidebar-leave-active {
	transition: $transition;
}

.sidebar-enter-from,
.sidebar-leave-to {
	margin-left: -$sidebar-width;
}
</style>
