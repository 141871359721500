import axios from 'axios'

const rest = {
	state: {
		url: 'https://test.a.rest.taxi.hopintaxi.com:5501'
	},

	getters: {
		getRestUrl: (state) => state.url
	},

	actions: {
		/**
		 * @param {object} {formData, endpoint}
		 * @returns promise
		 */

		handleRestPost({ getters }, connObj) {
			const form = { ...connObj.formData }
			form.userId = getters.getUser.userId
			form.userKey = getters.getUser.userKey

			return new Promise((resolve, reject) => {
				axios
					.post(`${getters.getRestUrl}${connObj.endpoint}`, form)
					.then((res) => resolve(res))
					.catch((err) => reject(err))
			})
		}
	}
}

export default rest
