const translations = {
	state: {
		dark: false
	},

	getters: {
		isDarkTheme: (state) => state.dark
	},

	mutations: {
		SET_DARK_THEME(state, bool) {
			state.dark = bool
		}
	},

	actions: {
		setDarkTheme({ commit }, bool) {
			commit('SET_DARK_THEME', bool)
			if (bool) {
				document.documentElement.setAttribute('theme', 'dark')
				window.localStorage.setItem('dark', true)
			} else {
				document.documentElement.setAttribute('theme', 'light')
				window.localStorage.setItem('dark', false)
			}
		}
	}
}

export default translations
